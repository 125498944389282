<template>
  <DeleteModal
    :actions="getActionsCategory.text"
    @deleteOrder="deleteItem"
    @cancelDel="cancelHandler"
  />
</template>

<script>
import { mapGetters } from "vuex";
import DeleteModal from "@/components/table/DelToponym/DeleteModal/DeleteModal";

export default {
  name: "DelCategory",
  components: { DeleteModal },
  computed: {
    ...mapGetters(["getActionsCategory", "getActions"]),
  },
  methods: {
    deleteItem() {
      this.$store.dispatch("SET_CATEGORY", {
        method: this.getActionsCategory.method,
        url: this.getActionsCategory.url + "/" + this.getActionsCategory.id,
        order: this.getActionsCategory.order,
        commitName: this.getActionsCategory.commitName,
        id: this.getActionsCategory.id,
        idImage: this.getActionsCategory.idImage,
        notify: this.$notify,
        modal: this.$modal,
        modalName: "delete-toponym",
        nameCategory: this.getActionsCategory.url,
      });
      this.$emit("close");
    },
    cancelHandler() {
      this.$store.commit("SET_ACTIONS", {});
      this.$emit("close");
    },
  },
};
</script>
